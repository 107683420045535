
import { store } from './store';
import { authActions } from '../../modules/auth/authentication.actions';

export function validateResponse(response) {
  
  if(response.status === 401) {
    store.dispatch(authActions.invalidateToken());
    Promise.reject()
    return null;
  } 
  return response;
}