import { authHeader } from '../../shared/helpers/authHeader';
import { validateResponse } from '../../shared/helpers/validateResponse';
import axios from 'axios';

export const contactService = {
  getAll,
};

function getAll(type) {

  const requestOptions = {
    url: process.env.REACT_APP_SERVER_URL + `/users/me/contacts/true`,
    method: 'GET',
    headers: authHeader(),
  }

  return axios(requestOptions)
    .then(validateResponse)
    .then(response => {
      return response.data;
    });
}