import React, { Component } from 'react';

import { LinkedIn } from 'react-linkedin-login-oauth2';
import "./index.css";
import { MDBBtn, MDBFreeBird, MDBCard, MDBContainer, MDBEdgeHeader, MDBIcon, MDBTypography } from "mdbreact";
import { connect } from 'react-redux';
import { authActions } from '../../authentication.actions';
import { withRouter } from 'react-router-dom';
import Loader from 'react-loader-spinner'


class Login extends Component {

  componentDidMount = () => {
    // this.props.fetchStateFromStorage();
  }

  handleSuccess = (data) => {
    this.props.login(data.code)
    .then( () => {
      this.props.getMyProfile()
    })
  }

  handleFailure = (error) => {
    // show error message in a
  }

  render() {
    
    return (
      <div>
        <MDBEdgeHeader color="primary"></MDBEdgeHeader>
        <MDBContainer className="mt-5">

          <MDBFreeBird style={{ marginTop: -350 + "px" }} className=" d-flex flex-column justify-content-center">
            <MDBIcon className="white-text mt-5 center" icon="headset" size="5x" />
            <MDBTypography colorText="white" className="mt-3 mb-3 app-name center" tag='h1'>MEET & GREET</MDBTypography>

            {this.props.loading ? <Loader
              type="Grid"
              className="center loader"
              color="#673ab7"
              height={150}
              width={150}
            /> :
              <MDBCard className="p-5 center login-card" >

                <MDBTypography colorText="primary" className="mt-3 mb-2" tag='h2'>The only networking tool you will ever need.</MDBTypography>
                <MDBTypography colorText="primary" className="mt-3" tag='h5'> Join now and be matched with potential business partners</MDBTypography>
                <LinkedIn
                  clientId={process.env.REACT_APP_LI_CLIENT_ID}
                  onFailure={this.handleFailure}
                  onSuccess={this.handleSuccess}
                  redirectUri={process.env.REACT_APP_LI_RESPONSE_URL}
                  scope="r_liteprofile+r_emailaddress"
                  renderElement={({ onClick, disabled }) => (
                    <MDBBtn onClick={onClick} disabled={disabled} className="rounded-pill mt-5" color="deep-purple"> <MDBIcon size="2x" fab icon="linkedin" className="mr-2" /> Login With linkedin</MDBBtn>
                  )}
                />
              </MDBCard>}
          </MDBFreeBird>
        </MDBContainer>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    loading: state.authentication.loading,
  }
}


const actionCreators = {
  login: authActions.login,
  getMyProfile: authActions.getMyProfile,
  fetchStateFromStorage: authActions.fetchStateFromStorage
};


export default withRouter(connect(mapStateToProps, actionCreators)(Login));