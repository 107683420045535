import { contactConstants } from './contact.constants';
import { contactService } from './contact.service';
import { store } from '../../shared/helpers/store';
import { alertActions } from '../../shared/actions/alert.actions';

export const contactActions = {
  getAll,
};

function getAll() {

  return dispatch => {
    dispatch(request());

    if(!store.getState().authentication.user) {
      return;
    }
    contactService.getAll()
      .then(
        
        response => {

          if(response.result === "success") {
            dispatch(success(response.body.contacts));
          }
        },
        error => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
      );
  }

  function request() { return { type: contactConstants.GET_ALL_REQUEST } }
  function success(contacts) { return { type: contactConstants.GET_ALL_SUCCESS, contactList: contacts} }
  function failure(error) { return { type: contactConstants.GET_ALL_ERROR, error } }

}