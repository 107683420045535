import { contactConstants } from './contact.constants';

const initialState = { contactList: [] };

export function contactReducer(state = initialState, action) {

  switch (action.type) {

    case contactConstants.GET_ALL_SUCCESS:
      return {
        ...state,
        contactList : action.contactList
      }

    default:
      return state
  }
}