import { preferenceConstants } from './preference.constants';

const initialState = { interests: [], looking_for: [], offering: [], language: [], users_interests: [], users_looking_for: [], users_offering: [], users_language: [], userInfo: {} };

export function preferenceReducer(state = initialState, action) {

  switch (action.type) {

    case preferenceConstants.GET_SUCCESS:
      state[action.preferenceType] = action.preferences;
      return {
        ...state,
      }

    case preferenceConstants.GET_ALL_SUCCESS:
      return {
        ...state,
        interests: action.preferences.interests,
        looking_for: action.preferences.looking_for,
        offering: action.preferences.offering,
        language: action.preferences.language,
      }

    case preferenceConstants.GET_ALL_FROM_USER_SUCCESS:
      return {
        ...state,
        users_interests: action.usersPreferences.interests,
        users_looking_for: action.usersPreferences.looking_for,
        users_offering: action.usersPreferences.offering,
        users_language: action.usersPreferences.language,
      }

    case preferenceConstants.SAVE_SUCCESS:
      return {
       ...state
      }

    case preferenceConstants.GET_FROM_USER_SUCCESS:
      state["users_" + action.preferenceType] = action.usersPreferences;
      console.log("users" + action.preferenceType, state["users" + action.preferenceType], action.usersPreferences);
      return {
       ...state
      }

    default:
      return state
  }
}