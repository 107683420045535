import { lobbyConstants } from '../../lobby.constants';
import { lobbyService } from '../../lobby.service';
import { alertActions } from '../../../../shared/actions/alert.actions';
import { authService } from '../../../auth/authentication.service';
import { alertConstants } from '../../../../shared/constants/alert.constants';

export const lobbyActions = {
  connectSocket,
  searchMatch,
  startCall,
  endCall,
  shareContact,
  registerSocketEvents
};

function connectSocket(token) {

  let socket = lobbyService.connectSocket(token);

  if (!socket.hasListeners(lobbyConstants.REFRESH_TOKEN)) {
    socket.on(lobbyConstants.REFRESH_TOKEN, () => {

      const refreshToken = localStorage.getItem('refreshToken') || 'refresh123xyz';
      const user = JSON.parse(localStorage.getItem('user'));

      if(typeof user === "undefined") {
        return;
      }

      authService.refreshToken(refreshToken, user.username, user.email, user.user_id)
        .then(response => {
          socket.emit(lobbyConstants.REFRESH_TOKEN, { token: response.data.token });
        });
    })
  }

  this.registerSocketEvents(socket);

  return { type: lobbyConstants.CONNECT_SOCKET, socket: socket };
}

function registerSocketEvents(socket) {
  return dispatch => {

    if (!socket.hasListeners(lobbyConstants.NO_MATCH_FOUND)) {

      socket.on(lobbyConstants.NO_MATCH_FOUND, () => {
        alertActions.error(alertConstants.Message.NO_MATCH_FOUND);
        return dispatch({ type: lobbyConstants.NO_MATCH_FOUND });
      })
    }

    if (!socket.hasListeners(lobbyConstants.HANDSHAKE_FAILED)) {

      socket.on(lobbyConstants.HANDSHAKE_FAILED, () => {
        alertActions.error(alertConstants.Message.HANDSHAKE_FAILED);
        return dispatch({ type: lobbyConstants.HANDSHAKE_FAILED });
      })
    }


    if (!socket.hasListeners(lobbyConstants.NO_PREFERENCES)) {

      socket.on(lobbyConstants.NO_PREFERENCES, () => {
        alertActions.error(alertConstants.Message.NO_PREFERENCES);
        return dispatch({ type: lobbyConstants.NO_PREFERENCES });
      })
    }

    if (!socket.hasListeners(lobbyConstants.SHARE_CONTACT)) {

      socket.on(lobbyConstants.SHARE_CONTACT, () => {
        return dispatch({ type: lobbyConstants.SHARE_CONTACT });
      })
    }
  }
}

function searchMatch(socket, dispatch) {

  lobbyService.searchMatch(socket);
  return { type: lobbyConstants.SEARCH_MATCH };
 
}

function startCall() {
  return { type: lobbyConstants.CALL_STARTED };
}

function endCall() {
  return { type: lobbyConstants.CALL_END };
}

function shareContact(socket) {
  lobbyService.shareContact(socket);
  return { type: lobbyConstants.SHARE_CONTACT_ACCEPT };
}
