import axios from 'axios';
import { authHeader } from '../../shared/helpers/authHeader';
import Interceptor from '../../shared/helpers/axios.interceptor';
import { validateResponse } from '../../shared/helpers/validateResponse';
import { store } from '../../shared/helpers/store';
import { authConstants } from './authentication.constants';


export const authService = {
  login,
  logout,
  refreshToken,
  getMyProfile
};


axios.interceptors.response.use(undefined, Interceptor(axios));

function login(authCode) {

  const requestOptions = {
    url: process.env.REACT_APP_SERVER_URL + "/users/login",
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    data: JSON.stringify({
      code: authCode
    })
  }
  
  return axios(requestOptions) 
    
    .then(response => {  
      if (response.data.result === "success") {
        return response.data.body;
      } else {
        logout();
      }
    });
}

function logout() {
  localStorage.removeItem('token');
  localStorage.removeItem('refreshToken');
  localStorage.removeItem('user');
}

function refreshToken(refreshToken, username, email, userId) {

  const requestOptions = {
    url: process.env.REACT_APP_SERVER_URL + "/users/refreshToken",
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    data: JSON.stringify({
      refreshToken: refreshToken,
      username: username,
      email: email,
      userId: userId
    })
  }

  return axios(requestOptions)
    .then ( response => {
       
      localStorage.setItem('token', response.data.token);
      localStorage.setItem('refreshToken', response.data.refreshToken);

      store.dispatch({ type: authConstants.token.TOKEN_REFRESHED, tokenInfo: response.data});

      return response;
    })
}

function getMyProfile() {

  const requestOptions = {
    url: process.env.REACT_APP_SERVER_URL + "/users/me/profile",
    method: 'GET',
    headers: authHeader(),
  }
  return axios(requestOptions)
    .then(validateResponse)
    .then(response => {
      return response.data;
    });
}


