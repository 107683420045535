import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
// import logo from './logo.svg';
import PublicLayout from './app/shared/components/layouts/PublicLayout';
import ProtectedLayout from './app/shared/components/layouts/ProtectedLayout';
import { toast } from 'react-toastify';
import './App.scss';
import 'react-toastify/dist/ReactToastify.css';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"


toast.configure();

function App() {
  return <div>

    <BrowserRouter>
      <Switch>
        <Route path='/app' component={ProtectedLayout} />
        <Route path='/' component={PublicLayout} />
      </Switch>
    </BrowserRouter>
  </div>
}

export default App;
