const user = {
 
  LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
  LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
  LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',
  LOGOUT: 'USERS_LOGOUT',

  PROFILE_REQUEST: 'USERS_PROFILE_REQUEST',
  PROFILE_SUCCESS: 'USERS_PROFILE_SUCCESS',
  PROFILE_FAILURE: 'USERS_PROFILE_FAILURE',
  FETCH_STATE_STORAGE_SUCCESS: 'FETCH_STATE_STORAGE_SUCCESS',
  FETCH_STATE_STORAGE_ERROR: 'FETCH_STATE_STORAGE_ERROR',
  FETCH_STATE_STORAGE_REQUEST: 'FETCH_STATE_STORAGE_REQUEST',
};

const token = {
 
  INVALID_TOKEN: 'INVALID_TOKEN',
  REFRESHING_TOKEN: 'REFRESHING_TOKEN',
  TOKEN_REFRESHED: 'TOKEN_REFRESHED',
  RESEND_TOKEN: 'RESEND_TOKEN',
  REFRESH_EXPIRED: 'REFRESH_EXPIRED',
};

export const authConstants = {
  user,
  token,
};
