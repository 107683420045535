import React, { Component } from "react";
import { MDBNavbar, MDBNavbarBrand, MDBNavbarNav, MDBNavItem, MDBIcon, MDBNavbarToggler, MDBCollapse, MDBDropdown,
MDBDropdownToggle, MDBDropdownMenu, MDBDropdownItem } from "mdbreact";
import { connect } from 'react-redux';
import { authActions } from '../../../modules/auth/authentication.actions'; 


class Header extends Component {
state = {
  isOpen: false
};

toggleCollapse = () => {
  this.setState({ isOpen: !this.state.isOpen });
}

render() {
  
  return (
      <MDBNavbar color="primary" dark expand="md">
        <MDBIcon className="white-text mr-2" icon="headset" size="2x" />
        <MDBNavbarBrand href="/app/lobby">
          <strong className="white-text">Meet & Greet</strong>
        </MDBNavbarBrand>
        <MDBNavbarToggler onClick={this.toggleCollapse} />
        <MDBCollapse id="navbarCollapse3" isOpen={this.state.isOpen} navbar>
          <MDBNavbarNav left>
           
          </MDBNavbarNav>
          <MDBNavbarNav right>
            <MDBNavItem>
              <MDBDropdown>
                <MDBDropdownToggle caret nav >
                  <img style= {{width: 40 + "px"}} src={ this.props.profilePicture } className="small rounded-circle img-fluid" alt="" />
                </MDBDropdownToggle>
                <MDBDropdownMenu right className="dropdown-default">
                  <MDBDropdownItem href="/app/lobby">Lobby</MDBDropdownItem>
                  {/* <MDBDropdownItem href="/app/preferences">Preferences</MDBDropdownItem> */}
                  <MDBDropdownItem href="/app/contacts">Contacts</MDBDropdownItem>
                  <MDBDropdownItem onClick={this.props.logout} href="#!">Log out</MDBDropdownItem>
                </MDBDropdownMenu>
              </MDBDropdown>
            </MDBNavItem>
          </MDBNavbarNav>
        </MDBCollapse>
      </MDBNavbar>
    );
  }
}

function mapState(state) {
  
  return {
    loggedIn: state.authentication.loggedIn,
    profilePicture: state.authentication.user.profile_picture_url
  }
}

const actionCreators = {
  logout: authActions.logout,
};

export default connect(mapState, actionCreators)(Header);