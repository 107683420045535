import React, { Component } from 'react';
import "./index.css";

class RemoteVideo extends Component {

  constructor(props) {

    super(props);
    this.videoRef = React.createRef();
  }

  enterFullScreen = () => {

    if (this.videoRef.current.requestFullscreen) {
      this.videoRef.current.requestFullscreen();
    } else if (this.videoRef.current.mozRequestFullScreen) {
      this.videoRef.current.mozRequestFullScreen();
    } else if (this.videoRef.current.webkitRequestFullscreen) {
      this.videoRef.current.webkitRequestFullscreen();
    }
  }

  exitFullScreen = () => {
    
    if (this.videoRef.current.exitFullscreen) {
      this.videoRef.current.exitFullscreen();
    } else if (this.videoRef.current.mozExitFullScreen) {
      this.videoRef.current.mozExitFullScreen();
    } else if (this.videoRef.current.webkitExitFullscreen) {
      this.videoRef.current.webkitExitFullscreen();
    }
  }

  componentDidUpdate = () => {
    this.videoRef.current.srcObject = this.props.stream;

    // if(this.props.stream) {
    //   this.enterFullScreen();
    // } else {
    //   this.exitFullScreen();
    // }
  }
  
  render() {
    return <video style = {this.props.style} className= {this.props.class} ref={this.videoRef} autoPlay playsInline></video>
  }
}
export default RemoteVideo;
