import React, { Component } from 'react';
import "./index.css";
import { MDBCardImage, MDBBtn, MDBCard, MDBTypography, MDBInput, MDBCardBody } from "mdbreact";
import { connect } from 'react-redux';
import TagInput from '../../../../shared/components/partials/TagInput';
import { Redirect } from 'react-router-dom';
import { preferenceActions } from '../../preference.actions';
import { authActions } from '../../../auth/authentication.actions';


class Preferences extends Component {

  constructor(props) {

    super(props);

    this.props.getMyPreferences();
    this.props.getAllPreferences();
    this.props.getMyProfile();

    this.state = {
      interests: [],
      lookingFor: [],
      offering: [],
      language: [],
      companyName: this.props.userInfo.company_name,
      shouldRedirect: false
    }
  }

  onSaveClick = () => {
    let preferences = [].concat(this.state.interests, this.state.lookingFor, this.state.offering, this.state.language);
    this.props.save(preferences, this.state.companyName);
    this.setState({ shouldRedirect: true });
  }

  componentWillReceiveProps = (nextProps) => {
    if (nextProps.userInfo.company_name !== this.props.userInfo.company_name) {
      this.setState({ companyName: nextProps.userInfo.company_name });
    }
  }

  onCompanyNameChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  }

  onInterestsChange = (tags) => {
    this.setState({ interests: tags });
  }

  onLookingForChange = (tags) => {
    this.setState({ lookingFor: tags });
  }

  onOfferingChange = (tags) => {
    this.setState({ offering: tags });
  }

  onLanguagesChange = (tags) => {
    this.setState({ language: tags });
  }

  render() {

    if (!this.state.shouldRedirect) {
      return (
        <div>

          <MDBCard wide className="narrow-window center" >
            <MDBCardImage
              className=' p-2 pt-4 view view-cascade primary'
              cascade
              tag='div'>
              <MDBTypography colorText="white" className=" mb-3 ml-3 center" tag='h1'>Configuration</MDBTypography>
            </MDBCardImage>
            <MDBCardBody className="p-5">
              <MDBTypography colorText="primary" className="mt-3 mb-3 center" on tag='h4'>Set company name *</MDBTypography>
              <MDBInput name="companyName" ref="companyNameInput" value={this.state.companyName} onChange={this.onCompanyNameChange} label="Company Name" />

              <MDBTypography colorText="primary" className="mt-5 mb-3 center" tag='h4'>Select your interests *</MDBTypography>
              <TagInput placeholder={"Artificial Intelligence, Augmented Reality..."} tags={(this.props.usersInterests) ? this.props.usersInterests : []} suggestions={this.props.interests} onChange={this.onInterestsChange} />

              <MDBTypography colorText="primary" className="mt-5 mb-3 center" tag='h4'>What can you offer ? *</MDBTypography>
              <TagInput placeholder={"Development Services, Investment..."} tags={(this.props.usersLookingFor) ? this.props.usersLookingFor : []} suggestions={this.props.lookingFor} onChange={this.onOfferingChange} />

              <MDBTypography colorText="primary" className="mt-5 mb-3 center" tag='h4'>What are you looking for ? *</MDBTypography>
              <TagInput placeholder={"Development Services, Investment..."} tags={(this.props.usersOffering) ? this.props.usersOffering : []} suggestions={this.props.offering} onChange={this.onLookingForChange} />

              <MDBTypography colorText="primary" className="mt-5 mb-3 center" tag='h4'>Select your languages *</MDBTypography>
              <TagInput placeholder={"Italian, German, Spanish..."} tags={(this.props.usersLanguage) ? this.props.usersLanguage : []} suggestions={this.props.language} onChange={this.onLanguagesChange} />

              <MDBBtn color="deep-purple" onClick={this.onSaveClick} className="mt-5 narrow-input rounded-pill center" >Save</MDBBtn>
            </MDBCardBody>

          </MDBCard>
        </div>
      )
    } else {
      return (
        <Redirect to="/app/lobby" />
      )
    }
  }
}

function mapStateToProps(state) {

  return {
    interests: state.preference.interests,
    lookingFor: state.preference.looking_for,
    offering: state.preference.offering,
    language: state.preference.language,
    usersInterests: state.preference.users_interests,
    usersLookingFor: state.preference.users_looking_for,
    usersOffering: state.preference.users_offering,
    usersLanguage: state.preference.users_language,
    userInfo: state.authentication.user
  }
}

const actionCreators = {
  getAllPreferences: preferenceActions.getAll,
  getMyPreferences: preferenceActions.getMyPreferences,
  getMyProfile: authActions.getMyProfile,
  save: preferenceActions.save,
};


export default connect(mapStateToProps, actionCreators)(Preferences);