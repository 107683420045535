import React, { Component } from 'react';
import LoginPage from '../../../modules/auth/pages/login/index';
import Footer from '../partials/Footer';
import { Route, Switch, withRouter, Redirect } from 'react-router-dom';
import { LinkedInPopUp } from 'react-linkedin-login-oauth2';
import { connect } from "react-redux";



class PublicLayout extends Component {

  render() {

    if (!this.props.loggedIn) {

      return (
        <div>
          <Switch>
            <Route exact path="/" component={LoginPage} />
            <Route exact path='/signin' component={LoginPage} />
            <Route exact path='/linkedin' component={LinkedInPopUp} />
            <Route path='*' component={LoginPage} />
          </Switch>
          <Footer />
        </div >
      )
    } else {

      return (
        <Redirect to="/app/lobby" />
      )
    }
  }
}

function mapStateToProps(state) {

  return {

    loggedIn: state.authentication.loggedIn,
    firstTimeUser: state.authentication.firstTimeUser

  }
}

export default withRouter(connect(mapStateToProps, null)(PublicLayout));