import { lobbyConstants } from '../lobby/lobby.constants';
import socketIOClient from "socket.io-client";


export const lobbyService = {
  connectSocket,
  searchMatch,
  shareContact
};

function connectSocket(token) {
  let socket = socketIOClient(process.env.REACT_APP_SIGNALING_SERVER, { query: "token=" + token });
  return socket;
}

function searchMatch(socket) {
  socket.emit(lobbyConstants.SEARCH_MATCH);
}

function shareContact(socket) {
  socket.emit(lobbyConstants.SHARE_CONTACT);
}