import { lobbyConstants } from './lobby.constants';


const initialState = { loading: false, socket: null, isSharingContactsVisible: false, clearPeerConnection: false };

export function lobbyReducer(state = initialState, action) {
  switch (action.type) {
    case lobbyConstants.CONNECT_SOCKET:
      return {
        ...state,
        socket: action.socket
      };
    case lobbyConstants.NO_MATCH_FOUND:
      return {
        ...state,
        loading: false
      };
    case lobbyConstants.HANDSHAKE_FAILED:
      return {
        ...state,
        loading: false,
        clearPeerConnection: false
      };
    case lobbyConstants.NO_PREFERENCES:
      return {
        ...state,
        loading: false
      };
    case lobbyConstants.SEARCH_MATCH:
      return {
        ...state,
        loading: true,
        clearPeerConnection: false
      };
    case lobbyConstants.CALL_STARTED:
      return {
        ...state,
        loading: false,
        clearPeerConnection: false
      };
    case lobbyConstants.SHARE_CONTACT:
      return {
        ...state,
        isSharingContactsVisible: true
      };
    case lobbyConstants.SHARE_CONTACT_ACCEPT:
      return {
        ...state,
        isSharingContactsVisible: false
      };
    case lobbyConstants.CALL_END:
      return {
        ...state,
        isSharingContactsVisible: false,
        loading: false
      };
    default:
      return state
  }
}