
import { alertReducer } from './alert.reducer';
import { authenticationReducer } from '../../modules/auth/authentication.reducer';
import { preferenceReducer } from '../../modules/preferences/preference.reducer';
import { contactReducer } from '../../modules/contacts/contact.reducer';
import { lobbyReducer } from '../../modules/lobby/lobby.reducer';
import { combineReducers } from 'redux'

export default combineReducers({ alert: alertReducer,
                                 authentication: authenticationReducer, 
                                 preference: preferenceReducer,
                                 contacts: contactReducer,
                                 lobby: lobbyReducer});