import { createStore, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
// import { authenticationMiddleware } from '../middlewares/authentication.middleware';
import { createLogger } from 'redux-logger';
import rootReducer from '../reducers/baseReducer';

const loggerMiddleware = createLogger();

export const store = createStore(

    rootReducer,
    applyMiddleware(
        thunkMiddleware,
        loggerMiddleware,
        // authenticationMiddleware
    )
    
);