import React from "react";
import { MDBContainer, MDBFooter } from "mdbreact";
import 'mdbreact/dist/css/mdb.css';

const Footer = () => {
  return (
    <MDBFooter style={{fontSize: 18+"px"}}  color="primary" className="font-small pt-4 mt-4 fixed-bottom"> 
        <MDBContainer className="text-center fluid mb-3">
        Copyright   &copy; {new Date().getFullYear()}  <strong> Meet & Greet</strong>
        </MDBContainer>
    </MDBFooter>
  );
}

export default Footer;