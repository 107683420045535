import { authHeader } from '../../shared/helpers/authHeader';
import { validateResponse } from '../../shared/helpers/validateResponse';
import axios from 'axios';

export const preferenceService = {
  get,
  getAll,
  getMyPreferenceType,
  getMyPreferences,
  save,
};

function get(type) {

  const requestOptions = {
    url: process.env.REACT_APP_SERVER_URL + `/preferences/` + type,
    method: 'GET',
    headers: authHeader(),
  }

  return axios(requestOptions)
    .then(validateResponse)
    .then(response => {
      return response.data;
    });
}

function getAll() {

  const requestOptions = {
    url: process.env.REACT_APP_SERVER_URL + `/preferences`,
    method: 'GET',
    headers: authHeader(),
  }

  return axios(requestOptions)
    .then(validateResponse)
    .then(response => {
      return response.data;
    });
}

function getMyPreferenceType(type) {

  const requestOptions = {
    url: process.env.REACT_APP_SERVER_URL + `/users/me/preferences/` + type,
    method: 'GET',
    headers: authHeader(),
  }

  return axios(requestOptions)
    .then(validateResponse)
    .then(response => {
      return response.data;
    });
}

function getMyPreferences() {

  const requestOptions = {
    url: process.env.REACT_APP_SERVER_URL + "/users/me/preferences",
    method: 'GET',
    headers: authHeader(),
  }

  return axios(requestOptions)
    .then(validateResponse)
    .then(response => {
      return response.data;
    });
}

function save(preferences, companyName) {

  const requestOptions = {
    url: process.env.REACT_APP_SERVER_URL + "/users/me/preferences",
    method: 'POST',
    headers: authHeader(),
    data: JSON.stringify({
      preferences: preferences,
      companyName: companyName
    })
  }

  return axios(requestOptions)
    .then(validateResponse)
    .then(response => {
      return response.data;
    });
}
