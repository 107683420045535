export const preferenceConstants = {

  GET_REQUEST: 'PREFERENCES_GET_REQUEST',
  GET_SUCCESS: 'PREFERENCES_GET_SUCCESS',
  GET_ERROR: 'PREFERENCES_GET_ERROR',

  
  GET_ALL_REQUEST: 'PREFERENCES_GET_ALL_REQUEST',
  GET_ALL_SUCCESS: 'PREFERENCES_GET_ALL_SUCCESS',
  GET_ALL_ERROR: 'PREFERENCES_GET_ALL_ERROR',
  
  GET_FROM_USER_REQUEST: 'PREFERENCES_GET_FROM_USER_REQUEST',
  GET_FROM_USER_SUCCESS: 'PREFERENCES_GET_FROM_USER_SUCCESS',
  GET_FROM_USER_ERROR: 'PREFERENCES_GET_FROM_USER_ERROR',
  
  GET_ALL_FROM_USER_REQUEST: 'PREFERENCES_GET_ALL_FROM_USER_REQUEST',
  GET_ALL_FROM_USER_SUCCESS: 'PREFERENCES_GET_ALL_FROM_USER_SUCCESS',
  GET_ALL_FROM_USER_ERROR: 'PREFERENCES_GET_ALL_FROM_USER_ERROR',
  

  SAVE_REQUEST: 'PREFERENCES_SAVE_REQUEST',
  SAVE_SUCCESS: 'PREFERENCES_SAVE_SUCCESS',
  SAVE_FAILURE: 'PREFERENCES_SAVE_FAILURE',
  
  PREFERENCE_INTEREST: 'interests',
  PREFERENCE_LOOKING_FOR: 'looking_for',
  PREFERENCE_OFFERING: 'offering',
  PREFERENCE_LANGUAGE: 'language',

  GET_USER_PREFERENCE_INFO_REQUEST: 'GET_USER_PREFERENCE_INFO_REQUEST',
  GET_USER_PREFERENCE_INFO_SUCCESS: 'GET_USER_PREFERENCE_INFO_SUCCESS',
  GET_USER_PREFERENCE_INFO_ERROR: 'GET_USER_PREFERENCE_INFO_ERROR',


};