import { authConstants } from './authentication.constants';
import { authService } from './authentication.service';
import { alertActions } from '../../shared/actions/alert.actions';

export const authActions = {
    login,
    logout,
    invalidateToken,
    getMyProfile,
    fetchStateFromStorage
};

function login(code) {
    return dispatch => {
        dispatch(request());

        return authService.login(code)
              .then(
                  response => { 
                      dispatch(success(response));
                      localStorage.setItem('token', response.token);
                      localStorage.setItem('refreshToken', response.refreshToken);
                  },
                  error => {
                      dispatch(failure(error.toString()));
                      dispatch(alertActions.error(error.toString()));
                  }
              );
    };

    function request() { return { type: authConstants.user.LOGIN_REQUEST } }
    function success(body) { return { type: authConstants.user.LOGIN_SUCCESS, authInfo: body } }
    function failure(error) { return { type: authConstants.user.LOGIN_FAILURE, error } }
}

function logout() {
    authService.logout();
    return { type: authConstants.user.LOGOUT };
}

function invalidateToken() {
  return { type: authConstants.token.INVALID_TOKEN };
}

function fetchStateFromStorage() {

  const token = localStorage.getItem("token");
  const refreshToken = localStorage.getItem("refreshToken");
  const user = JSON.parse(localStorage.getItem("user"));

  if(token && user) {
    return { type: authConstants.user.FETCH_STATE_STORAGE_SUCCESS, token: token, refreshToken: refreshToken, user: user };
  } else {
    return { type: authConstants.user.FETCH_STATE_STORAGE_ERROR };
  }
}

function getMyProfile() {

  return dispatch => {
    dispatch(request());

    authService.getMyProfile()
      .then(
        response => {

          if(response.result === "success") {
            localStorage.setItem('user', JSON.stringify(response.body.userInfo));
            dispatch(success(response.body.userInfo));
          } 
        },
        error => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
      );
  }

  function request() { return { type: authConstants.user.PROFILE_REQUEST, method: getMyProfile() } }
  function success(userInfo) { return { type: authConstants.user.PROFILE_SUCCESS, user: userInfo} }
  function failure(error) { return { type: authConstants.user.PROFILE_FAILURE, error } }
}

