import React, { Component } from "react";
import ProfileCard from '../../components/profileCard';
import { contactActions } from '../../contact.actions';
import { connect } from 'react-redux';
import { MDBCol, MDBRow, MDBTypography } from "mdbreact";
import "./index.css";

class Contacts extends Component {


  componentDidMount = () => {
    this.props.getAllContacts();
  }

  render() {
    return (
      <div>
        {/* <MDBTypography colorText="primary" className=" mb-3 mt-3 ml-3 center" tag='h1'>Contacts</MDBTypography> */}

        <MDBRow className="row">
          {this.props.contactList.length > 0 ?
            this.props.contactList.map(function (contact) {
              return (
                <MDBCol md='3'>
                  <ProfileCard firstName={contact.firstName} lastName={contact.lastName} profilePicture={contact.profilePicture} companyName={contact.companyName} email={contact.email} />
                </MDBCol>)
            }) :
            <MDBTypography colorText="primary" className=" mt-5 center" tag='h5'>No contacts available.</MDBTypography>
          }
        </MDBRow>
      </div>
    );
  }
}

function mapStateToProps(state) {

  return {
    contactList: state.contacts.contactList,
  }
}

const actionCreators = {
  getAllContacts: contactActions.getAll,
};


export default connect(mapStateToProps, actionCreators)(Contacts);