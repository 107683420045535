import { alertConstants } from '../constants/alert.constants';
import { toast } from 'react-toastify';

export const alertActions = {
  success,
  error,
  clear
};

function success(message) {
  toast.success(message, { position: toast.POSITION.BOTTOM_RIGHT });
  return { type: alertConstants.State.SUCCESS, message };
}

function error(message) {
  toast.error(message, { position: toast.POSITION.BOTTOM_RIGHT });
  return { type: alertConstants.State.ERROR, message };
}

function clear() {
  return { type: alertConstants.State.CLEAR };
}