export const alertConstants = {
  
  State : {
    SUCCESS: 'ALERT_SUCCESS',
    ERROR: 'ALERT_ERROR',
    CLEAR: 'ALERT_CLEAR'
  },
  
  Message : {
    NO_MATCH_FOUND: 'No match found, please try again later.',
    NO_PREFERENCES: 'Please set your preferences before attempting to match with a user.',
    PREFERENCES_SAVED: 'Preferences saved successfully!',
    USER_LEFT: 'The other user left',
    SESSION_EXPIRED: 'Session is over, wait for the next one.',
    HANDSHAKE_FAILED: 'Could not create a session with the matched user, trying next one',
  }

};