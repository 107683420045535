import React, { Component } from 'react';
import { MDBContainer, MDBBtn, MDBModal, MDBModalHeader, MDBModalFooter } from 'mdbreact';

class ConfirmDialog extends Component {

  state = {
    show: this.props.show
  }

  toggle = () => {
    this.setState({
      show: !this.state.show
    });
  }


  componentWillReceiveProps(nextProps) {
      this.setState({show: nextProps.show}); 
  }

  onModalConfirm = () => {
    this.props.onModalConfirm();
    this.toggle();
  }

  render() {
    return (
      <MDBContainer>
        <MDBModal isOpen={this.state.show} toggle={this.toggle} side position="bottom-right">
          <MDBModalHeader toggle={this.toggle}> {this.props.title} </MDBModalHeader>
          <MDBModalFooter>
            <MDBBtn color="primary" onClick={this.onModalConfirm} >Yes</MDBBtn>
            <MDBBtn color="secondary" onClick={this.toggle}>No</MDBBtn>
          </MDBModalFooter>
        </MDBModal>
      </MDBContainer>
    );
  }
}

export default ConfirmDialog;