import { authConstants } from './authentication.constants';

const initialState = { loggedIn: false, user: null, loading: false, token: null, refreshToken: null, refreshingToken: false, firstTimeUser: false };

export function authenticationReducer(state = initialState, action) {

  switch (action.type) {

    case authConstants.user.LOGIN_REQUEST:
      return {
        ...state,
        loading: true
      };

    case authConstants.user.LOGIN_SUCCESS:
      return {
        ...state,
        token: (action.authInfo).token,
        refreshToken: (action.authInfo).refreshToken,
        firstTimeUser: (action.authInfo).firstTimeUser,
      };

    case authConstants.user.LOGIN_FAILURE:
      return {
        ...state,
        loading: false
      };

    case authConstants.user.LOGOUT:
      return {
        ...state,
        loggedIn: false,
        user: null,
        token: null,
        refreshToken: null,
      };

    case authConstants.token.INVALID_TOKEN:
      return {
        ...state,
      };

    case authConstants.token.TOKEN_REFRESHED:
      console.log("coming from above", action.tokenInfo);
      return {
        ...state,
        token: (action.tokenInfo).token,
        refreshToken: (action.tokenInfo).refreshToken,
      };


    case authConstants.user.PROFILE_REQUEST:
      return {
        ...state,
      }

    case authConstants.user.PROFILE_SUCCESS:
      return {
        ...state,
        loggedIn: true,
        loading: false,
        user: action.user
      }

    case authConstants.user.PROFILE_ERROR:
      return {
        ...state,
      }

    case authConstants.user.FETCH_STATE_STORAGE_SUCCESS:
      return {
        ...state,
        user: action.user,
        token: action.token,
        refreshToken: action.refreshToken,
        loggedIn: true
      }

    case authConstants.user.FETCH_STATE_STORAGE_ERROR:
      return {
        ...state,
      }

    default:
      return state
  }
}