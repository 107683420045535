import React, { Component } from 'react';
import Header from '../partials/Header';
import Footer from '../partials/Footer';
import { Redirect, Switch, withRouter, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import Lobby from '../../../modules/lobby/pages/lobby/index';
import Preferences from '../../../modules/preferences/pages/preference/index';
import Contacts from '../../../modules/contacts/pages/contact/index';


class ProtectedLayout extends Component {

  render() {

    if (this.props.loggedIn) {

      return (
        <div>
          <Header />
          <Switch>
            <Route path='/app/contacts' exact={true} component={Contacts} />
            <Route path='/app/preferences' exact={true} component={Preferences} />
            <Route path='/app/lobby' exact={true} component={Lobby} />
          </Switch>
          <Footer />
        </div>
      )
    } else {
      console.log("going public")
      return (
        <Redirect to="/" />
      )
    }
  }
}

function mapState(state) {
  return {
    loggedIn: state.authentication.loggedIn,
  }
}

export default withRouter(connect(mapState, null)(ProtectedLayout));