import React, { Component } from 'react';

import "./index.css";
import { MDBCardImage, MDBBtn, MDBCard, MDBTypography, MDBCardBody } from "mdbreact";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { lobbyActions } from "./lobby.actions";
import LocalVideo from "../../components/localVideo";
import RemoteVideo from "../../components/remoteVideo";
import RtcPeerConnection from "../../../../shared/helpers/RtcPeerConnection";
import Loader from 'react-loader-spinner'
import ConfirmDialog from '../../../../shared/components/partials/ConfirmDialog';



class Lobby extends Component {


  peerConnection;

  constructor(props) {
    super(props);


    this.state = {
      remoteStream: null,
      activeCall: false
    }
  }


  socketClose = () => {
    this.props.socket.close();
  }

  componentWillReceiveProps = (nextProps) => {
    if (nextProps.loading === false && this.props.loading === true && nextProps.clearPeerConnection) {
      console.log("this was called, hurray");
      // this.peerConnection.clearTracks();
    }
  }

  componentDidMount() {
    this.props.connectSocket(this.props.token);
    window.addEventListener('beforeunload', this.socketClose);
  }

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.socketClose);
    this.socketClose();
  }

  onSearchClick = () => {
    const socket = this.props.socket;
    this.props.searchMatch(socket);
  }

  onRemoteStreamStart = (stream) => {

    this.props.startCall();
    this.setState({ remoteStream: stream, activeCall: true });
  }

  onLocalStreamStart = (localStream) => {
    this.peerConnection = new RtcPeerConnection(this.props.socket, localStream, this.onRemoteStreamStart, this.onCloseCall);
  }

  onCloseCall = () => {
    this.setState({ remoteStream: null, activeCall: false });
    this.props.endCall();
  }

  onShareContact = () => {
    this.props.shareContact(this.props.socket);
  }

  render() {
    return (
      <div className="d-flex flex-row flex-wrap">
        <LocalVideo class={this.state.activeCall ? "small-video" : "normal-video center"} hasAudio={this.state.activeCall} onStreamStart={this.onLocalStreamStart} />
        {this.props.loading ? <Loader
          type="Grid"
          className="center loader"
          color="#673ab7"
          height={150}
          width={150}
        /> :
          <MDBCard style={this.state.activeCall ? { display: "none" } : { display: "flex" }} wide className="narrow-window center" >
            <MDBCardImage
              className=' p-2 pt-4 view view-cascade primary'
              cascade
              tag='div'>
              <MDBTypography colorText="white" className="mb-1 ml-3 center" tag='h1'>How it works</MDBTypography>
            </MDBCardImage>
            <MDBCardBody className="p-5">
              <MDBTypography colorText="primary" className="mt-3 mb-3 ml-3 center" on tag='h5'>- You will be randomly matched with other people.</MDBTypography>
              <MDBTypography colorText="primary" className="mt-3 mb-3 ml-3 center" on tag='h5'>- You have 90 seconds to meet and talk.</MDBTypography>
              <MDBTypography colorText="primary" className="mt-3 mb-3 ml-3 center" on tag='h5'>- In the last 10 seconds, you can choose to share your contact information.</MDBTypography>
              <MDBTypography colorText="primary" className="mt-3 mb-3 ml-3 center" on tag='h5'>- You can be matched automatically while in the lobby.</MDBTypography>
              <MDBTypography colorText="primary" className="mt-5 mb-3 ml-3 center" on tag='h3'>Happy Networking!</MDBTypography>
              <MDBBtn color="deep-purple" onClick={this.onSearchClick} className="narrow-input rounded-pill center" >Search Match</MDBBtn>
            </MDBCardBody>
          </MDBCard>}
        <RemoteVideo class="fullscreen-video" style={this.state.activeCall ? { display: "inline-block" } : { display: "none" }} stream={this.state.remoteStream} />
        <ConfirmDialog title={"Do you want to share contacts?"} onModalConfirm={this.onShareContact} show={this.props.isSharingContactsVisible} />
      </div >
    )
  }
}

function mapStateToProps(state) {
  return {
    token: state.authentication.token,
    socket: state.lobby.socket,
    loading: state.lobby.loading,
    isSharingContactsVisible: state.lobby.isSharingContactsVisible,
  }
}

const actionCreators = {
  connectSocket: lobbyActions.connectSocket,
  searchMatch: lobbyActions.searchMatch,
  startCall: lobbyActions.startCall,
  endCall: lobbyActions.endCall,
  shareContact: lobbyActions.shareContact,
  registerSocketEvents: lobbyActions.registerSocketEvents
};


export default withRouter(connect(mapStateToProps, actionCreators)(Lobby));