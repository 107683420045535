import { authService } from '../../modules/auth/authentication.service';
import { authActions } from '../../modules/auth/authentication.actions';

let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
  failedQueue.forEach(prom => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  })
  
  failedQueue = [];
}

const Interceptor = (axiosInstance) => (error) => {
    const _axios = axiosInstance;
    const originalRequest = error.config;

    if (error.response.status === 401 && !originalRequest._retry) {
        
        if (isRefreshing) {
            return new Promise(function(resolve, reject) {
            failedQueue.push({resolve, reject})
            }).then(token => {
            originalRequest.headers['x-access-token'] = token;
            return _axios.request(originalRequest);
            }).catch(err => {
            return Promise.reject(err);
            })
        }

        originalRequest._retry = true;
        isRefreshing = true;

        const refreshToken = localStorage.getItem('refreshToken') || 'refresh123xyz';
        const user = JSON.parse(localStorage.getItem('user'));

        if(typeof user === "undefined" || user === null) {
          authService.logout();
        }

        return new Promise((resolve, reject) => {

          authService.refreshToken(refreshToken, user.username, user.email, user.user_id)
            .then((response) => {
                _axios.defaults.headers.common['x-access-token'] = response.data.token;
                originalRequest.headers['x-access-token'] = response.data.token;
                processQueue(null, response.data.token);
                resolve(_axios(originalRequest));
            })
            .catch((err) => {
                processQueue(err, null);
                reject(err);
            })
            .then(() => { isRefreshing = false })
        })
    } else if(error.response.status === 400) {
      authActions.logout();
    }

    return Promise.reject(error);
};

export default Interceptor