import { webRtcConstants } from '../constants/webRtc.constants';
import { alertActions } from '../actions/alert.actions';
import { alertConstants } from '../constants/alert.constants';

export default function (socket, localStream, remoteStreamCallback, closeCallCallback) {

  this.peerConnection = null;
  this.socket = socket;
  this.localStream = localStream;
  this.remoteStreamCallback = remoteStreamCallback;
  this.closeCallCallback = closeCallCallback;


  this.close = () => {
    if (this.peerConnection) {
      this.peerConnection.close();
      delete this.peerConnection;
    }
  }

  this.clearTracks = () => {
    if (typeof this.peerConnection !== "undefined" && this.peerConnection != null)
      if (this.peerConnection) {
        this.peerConnection.close();
        delete this.peerConnection;
      }
  }

  this._registerSocketEvents = () => {
    this.socket.on(webRtcConstants.READY, () => { this._onPeerReady() });
    this.socket.on(webRtcConstants.OFFER, (message) => { this._onSocketOffer(message) });
    this.socket.on(webRtcConstants.CANDIDATE, (candidate) => { this._onSocketCandidate(candidate) });
    this.socket.on(webRtcConstants.ANSWER, (message) => { this._onSocketAnswer(message) });
    this.socket.on(webRtcConstants.LEAVE, () => { this._onSocketLeave() });
    this.socket.on(webRtcConstants.EXPIRED, () => { this._onSocketClose() });
  }

  this._initPeerConnection = () => {

    if (typeof this.peerConnection === "undefined" || this.peerConnection === null) {
      this.peerConnection = new RTCPeerConnection({
        iceServers: [{ urls: process.env.REACT_APP_STUN_SERVERS },
        {
          urls: process.env.REACT_APP_TURN_SERVERS,
          credential: process.env.REACT_APP_TURN_CREDENTIAL,
          username: process.env.REACT_APP_TURN_USERNAME
        }]
      });
    }
  }

  this._onIceCandidate = (event) => {
    if (event.candidate) {
      this.socket.emit(webRtcConstants.CANDIDATE, event.candidate);
    }
  }

  this._onSocketCandidate = (candidate) => {
    this.peerConnection.addIceCandidate(new RTCIceCandidate(candidate))
      .catch(e => console.error(e));
  };

  this._onSocketAnswer = (description) => {
    this.peerConnection.setRemoteDescription(description);
  };

  this._onPeerReady = () => {

    try {

      if(typeof this.peerConnection !== "undefined" && this.peerConnection !== null && this.peerConnection.iceConnectionState === "connected" ) {
        return;
      }

      this._initPeerConnection();

      this.localStream.getTracks().forEach(track => this.peerConnection.addTrack(track, this.localStream))

      // this.peerConnection.addStream(this.localStream);

      this.peerConnection.createOffer()
        .then(sdp => {
          this.peerConnection.setLocalDescription(sdp)
          return sdp;
        })
        .then((sdp) => {
          this.socket.emit(webRtcConstants.OFFER, sdp);
        });
    } catch (exception) {
      alert(exception);
    }

    this.peerConnection.ontrack = event => this.remoteStreamCallback(event.streams[0]);
    // this.peerConnection.onaddstream = event => this.remoteStreamCallback(event.stream);

    this.peerConnection.onicecandidate = (event) => this._onIceCandidate(event);
  }

  this._onSocketOffer = (description) => {

    try {
      this._initPeerConnection();

      if(typeof this.peerConnection !== "undefined" && this.peerConnection !== null && this.peerConnection.iceConnectionState === "connected" ) {
        return;
      }

      this.localStream.getTracks().forEach(track => this.peerConnection.addTrack(track, this.localStream))

      // this.peerConnection.addStream(this.localStream);

      this.peerConnection.setRemoteDescription(description)
        .then(() => this.peerConnection.createAnswer())
        .then(sdp => {
          this.peerConnection.setLocalDescription(sdp)
          return sdp;
        })
        .then((sdp) => {
          this.socket.emit(webRtcConstants.ANSWER, sdp);
        });
    } catch (exception) {
      alert(exception);
    }

    // this.peerConnection.onaddstream = event => this.remoteStreamCallback(event.stream);
    this.peerConnection.ontrack = event => this.remoteStreamCallback(event.streams[0]);
    this.peerConnection.onicecandidate = (event) => this._onIceCandidate(event);
  }


  this._onSocketLeave = () => {
    alertActions.error(alertConstants.Message.USER_LEFT);
    this.close();
    this.closeCallCallback();
  }

  this._onSocketClose = () => {
    alertActions.success(alertConstants.Message.SESSION_EXPIRED);
    this.close();
    this.closeCallCallback();
  }

  this._registerSocketEvents();

}