import React, { Component } from 'react';
import "./index.css";

class LocalVideo extends Component {

  mediaStreamConstraints = {
    video: true,
    audio: true
  };


  constructor(props) {

    super(props);
    this.videoRef = React.createRef();

  }

  componentWillUnmount = () => {
    if(this.localStream && this.localStream.getTracks().length > 0) {
      this.localStream.getTracks().forEach(function(track) {
        track.stop();
      });
    }
  }

  componentDidMount = () => {
    this.initUserMedia();
  }

  toggleAudio = (hasAudio) => {
    if(this.localStream && this.localStream.getAudioTracks().length > 0){
      this.localStream.getAudioTracks().forEach(track => {
        track.enabled = hasAudio;
      });
    }
  }

  componentWillReceiveProps = (nextProps) => {
    if(typeof nextProps.hasAudio !== "undefined" && typeof nextProps.hasAudio !== null) {
      this.toggleAudio(nextProps.hasAudio);
    }
  }

  gotLocalMediaStream = (mediaStream) => {
    this.localStream = mediaStream;
    this.videoRef.current.srcObject = mediaStream;
    this.props.onStreamStart(mediaStream);
    // this.toggleAudio(this.props.hasAudio);
  }

  handleLocalMediaStreamError = (error) => {
    console.log('navigator.getUserMedia error: ', error);
  }

  initUserMedia = () => {

    if(navigator.mediaDevices) {
      return navigator.mediaDevices
      .getUserMedia(this.mediaStreamConstraints)
      .then(this.gotLocalMediaStream)
      .catch(this.handleLocalMediaStreamError);
    }
  }

  render() {
    return <video className={this.props.class} muted="muted" ref={this.videoRef} autoPlay playsInline></video>

  }
}
export default LocalVideo;
