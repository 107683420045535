import { preferenceConstants } from './preference.constants';
import { preferenceService } from './preference.service';
import { store } from '../../shared/helpers/store';
import { alertActions } from '../../shared/actions/alert.actions';
import { alertConstants } from '../../shared/constants/alert.constants';

export const preferenceActions = {
  get,
  getAll,
  getMyPreferenceType,
  getMyPreferences,
  save
};

function get(type) {

  return dispatch => {
    dispatch(request(type));

    if(!store.getState().authentication.user) {
      return;
    }

    preferenceService.get(type)
      .then(
        response => {

          if(response.result === "success") {
            dispatch(success(response.body.preferences, type));
          }
        },
        error => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
      );
  }

  function request(type) { return { type: preferenceConstants.GET_REQUEST, method: get(type) } }
  function success(preferences, preferenceType) { return { type: preferenceConstants.GET_SUCCESS, preferences: preferences, preferenceType: preferenceType } }
  function failure(error) { return { type: preferenceConstants.GET_ERROR, error } }

}

function getAll() {

  return dispatch => {
    dispatch(request());

    if(!store.getState().authentication.user) {
      return;
    }

    preferenceService.getAll()
      .then(
        response => {

          if(response.result === "success") {
            dispatch(success(response.body.preferences));
          } 
        },
        error => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
      );
  }

  function request() { return { type: preferenceConstants.GET_ALL_REQUEST, method: getAll() } }
  function success(preferences) { return { type: preferenceConstants.GET_ALL_SUCCESS, preferences: preferences } }
  function failure(error) { return { type: preferenceConstants.GET_ALL_ERROR, error } }

}

function getMyPreferenceType(type) {
  
  return dispatch => {
    dispatch(request(type));

    if(!store.getState().authentication.user) {
      return;
    }

    preferenceService.getMyPreferenceType(type)
      .then(
        response => {

          if(response.result === "success") {
            dispatch(success(response.body.preferences, type));
          } 
        },
        error => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
      );
  }

  function request(type) { return { type: preferenceConstants.GET_FROM_USER_REQUEST, method: getMyPreferenceType(type) } }
  function success(usersPreferences, preferenceType) { return { type: preferenceConstants.GET_FROM_USER_SUCCESS, usersPreferences: usersPreferences, preferenceType: preferenceType } }
  function failure(error) { return { type: preferenceConstants.GET_FROM_USER_ERROR, error } }

}

function getMyPreferences() {

  
  return dispatch => {
    dispatch(request());

    if(!store.getState().authentication.user) {
      return;
    }

    preferenceService.getMyPreferences()
      .then(
        response => {

          if(response.result === "success") {
            dispatch(success(response.body.preferences));
          } else {
            
          }
        },
        error => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
      );
  }

  function request() { return { type: preferenceConstants.GET_ALL_FROM_USER_REQUEST, method: getMyPreferences() } }
  function success(usersPreferences) { return { type: preferenceConstants.GET_ALL_FROM_USER_SUCCESS, usersPreferences: usersPreferences } }
  function failure(error) { return { type: preferenceConstants.GET_ALL_FROM_USER_ERROR, error } }

}

function save(preferences, companyName) {
  
  return dispatch => {
    dispatch(request(preferences, companyName));

    if(!store.getState().authentication.user) {
      return;
    }

    preferenceService.save(preferences, companyName)
      .then(
        response => {
          if(response.result === "success") {
            dispatch(success());
            dispatch(alertActions.success(alertConstants.Message.PREFERENCES_SAVED));
          } 
        },
        error => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
      );
  }

  function request(preferences, companyName) { return { type: preferenceConstants.SAVE_REQUEST, method: save(preferences, companyName) } }
  function success() { return { type: preferenceConstants.SAVE_SUCCESS } }
  function failure(error) { return { type: preferenceConstants.SAVE_ERROR, error } }

}
