import React, { Component } from 'react';
import { MDBCard, MDBCardBody, MDBCardImage } from 'mdbreact';
import "./index.css";

class ProfileCard extends Component {


  render() {
    
    return (
      <MDBCard testimonial className = "card">
       <MDBCardImage
            className=' p-5 view view-cascade primary'
            cascade
            tag='div' />
        <img className='mx-auto white rounded-circle profile-image'
          src={this.props.profilePicture}
          alt=''
        />
        <MDBCardBody>
          <h4 className='card-title'> {this.props.firstName + " " + this.props.lastName}</h4>
          <hr />
          <p> Company : {this.props.companyName}</p>
          <p> Email : {this.props.email}</p>
        </MDBCardBody>
      </MDBCard>
    )
  }
}

export default ProfileCard;