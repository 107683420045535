import React, { Component } from 'react';
import ReactTags from 'react-tag-autocomplete';
import "./index.css";

class TagInput extends Component {

  state = {
    tags: this.props.tags,
    suggestions: this.props.suggestions,
    tagsFromProps: false
  }

  componentWillReceiveProps(nextProps) {
    console.log("receiving", this.state.tagsFromProps, nextProps.tags.length);
    if(this.state.tagsFromProps === false && nextProps.tags.length > 0) {
      console.log("RECEIVING CHILD", nextProps.tags);
      this.setState({tags: nextProps.tags, suggestions: nextProps.suggestions, tagsFromProps: true});
      this.props.onChange(nextProps.tags);
    }
  }

  handleDelete = (i) => {
    const tags = this.state.tags.slice(0);
    this.props.onChange(tags);
    tags.splice(i, 1);
    this.setState({ tags });
  }

  handleAddition = (tag) => {
    
    for( let index = 0; index < this.state.tags.length; index ++) {
      if(this.state.tags[index].id === tag.id) {
        return;
      }
    }
    const tags = [].concat(this.state.tags, tag);
    this.setState({ tags: tags });
    this.props.onChange(tags);
    console.log("child state", this.state)
  }

  render() {

    return (
      
      <ReactTags
        tags={this.state.tags}
        suggestions={this.props.suggestions}
        handleDelete={this.handleDelete.bind(this)}
        handleAddition={this.handleAddition.bind(this)} 
        placeholder={this.props.placeholder}
        classNames={this.props.classNames}
        delimiters={[9, 13]}
        autofocus={false}
        />
    )
  }
}

export default TagInput;