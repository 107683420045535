export const lobbyConstants = {
 
  CONNECT_SOCKET: 'CONNECT_SOCKET',
  SEARCH_MATCH: 'SEARCH_MATCH',
  REFRESH_TOKEN: 'REFRESH_TOKEN',
  NO_MATCH_FOUND: 'NO_MATCH_FOUND',
  NO_PREFERENCES: 'NO_PREFERENCES',
  CALL_STARTED : 'CALL_STARTED',
  CALL_END : 'CALL_END',
  SHARE_CONTACT : 'SHARE_CONTACT',
  SHARE_CONTACT_ACCEPT : 'SHARE_CONTACT_ACCEPT',
  HANDSHAKE_FAILED : 'HANDSHAKE_FAILED'
};